import React, { useState, useEffect } from 'react';
import styles from './style.module.css';
import Sidebar from '../components/sidebar';
import { useLocation, Link } from 'react-router-dom';

const FormPage = () => {
    const location = useLocation();
    const isCreatePage = location.pathname === '/new-coupon' || location.pathname === '/new-business';

    const [formData, setFormData] = useState({
        couponNameAz: '',
        couponNameEn: '',
        descriptionAz: '',
        descriptionEn: '',
        startDate: '',
        endDate: '',
    });

    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (!isCreatePage) {
            const couponId = location.pathname.split('/edit-coupon/')[1];
            fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/coupons/${couponId}`)
                .then((response) => response.json())
                .then((couponData) => {
                    setFormData({
                        couponNameAz: couponData.coupon_name.az,
                        couponNameEn: couponData.coupon_name.en,
                        descriptionAz: couponData.description.az,
                        descriptionEn: couponData.description.en,
                        startDate: couponData.start_date,
                        endDate: couponData.end_date,
                    });
                })
                .catch((error) => {
                    setErrorMessage('Xəta baş verdi');
                });
        }
    }, [isCreatePage, location.pathname]);

    const handleSubmit = () => {
        setIsSubmitting(true);

        if (isCreatePage) {
            const newData = {
                business_id: localStorage.getItem('listing_id'),
                coupon_name: {
                    az: formData.couponNameAz,
                    en: formData.couponNameEn,
                },
                description: {
                    az: formData.descriptionAz,
                    en: formData.descriptionEn,
                },
                start_date: formData.startDate,
                end_date: formData.endDate,
            };

            fetch('https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/coupons/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newData),
            })
                .then((response) => response.json())
                .then(() => {
                    setSuccessMessage('Əməliyyat uğurla icra edildi');
                    // after 2 seconds redirect to "/dashboard" route
                    setTimeout(() => {
                        window.location.href = '/dashboard';
                    }, 2000);
                })
                .catch((error) => {
                    console.log(error);
                    setErrorMessage('Xəta baş verdi');
                });
        } else {
            const couponId = location.pathname.split('/edit-coupon/')[1];
            const updatedData = {
                business_id: couponId,
                coupon_name: {
                    az: formData.couponNameAz,
                    en: formData.couponNameEn,
                },
                description: {
                    az: formData.descriptionAz,
                    en: formData.descriptionEn,
                },
                start_date: formData.startDate,
                end_date: formData.endDate,
            };

            fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/coupons/${couponId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedData),
            })
                .then((response) => response.json())
                .then(() => {
                    setSuccessMessage('Əməliyyat uğurla icra edildi');
                })
                .catch((error) => {
                    setErrorMessage('Xəta baş verdi');
                });
        }
    };

    useEffect(() => {
        if (successMessage || errorMessage) {
            setIsSubmitting(false);
        }
    }, [successMessage, errorMessage]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.sidebarContainer}>
                <Sidebar />
            </div>
            <div className={styles.mainContent}>

                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                <input
                    type="text"
                    placeholder="Kampaniya Adı (Azərbaycanca)"
                    value={formData.couponNameAz}
                    onChange={(e) => setFormData({ ...formData, couponNameAz: e.target.value })}
                />
                <input
                    type="text"
                    placeholder="Kampaniya Adı (Rusca)"
                    value={formData.couponNameEn}
                    onChange={(e) => setFormData({ ...formData, couponNameEn: e.target.value })}
                />
                <input
                    type="text"
                    placeholder="Ətraflı Məlumat (Azərbaycanca)"
                    value={formData.descriptionAz}
                    onChange={(e) => setFormData({ ...formData, descriptionAz: e.target.value })}
                />
                <input
                    type="text"
                    placeholder="Ətraflı Məlumat (Rusca)"
                    value={formData.descriptionEn}
                    onChange={(e) => setFormData({ ...formData, descriptionEn: e.target.value })}
                />
                <input
                    type="text"
                    placeholder="Başlanğıc tarixi (DD/MM/YYYY)"
                    value={formData.startDate}
                    onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
                />
                <input
                    type="text"
                    placeholder="Bitmə tarixi (DD/MM/YYYY)"
                    value={formData.endDate}
                    onChange={(e) => setFormData({ ...formData, endDate: e.target.value })}
                />
                <button onClick={handleSubmit} disabled={isSubmitting}>
                    {isSubmitting ? 'Gözləyin....' : 'Göndər'}
                </button>
            </div>
        </div>
    );
};

export default FormPage;
