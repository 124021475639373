import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './style.module.css';

const Sidebar = ({ data }) => {
    const listing_id = localStorage.getItem('listing_id');

    return (
        <div className={styles.sidebar}>
            <ul>
                <li>
                    <NavLink to="/dashboard" activeClassName={styles.active}>
                        Ümumi Məlumatlar
                    </NavLink>
                </li>
                <li>
                    <NavLink to="/campaigns" activeClassName={styles.active} state={{ data }}>
                        Kampaniyalar
                    </NavLink>
                </li>

                {listing_id == "1" ? (
                    <li>
                        <NavLink to="/users" activeClassName={styles.active} state={{ data }}>
                            İstifadəçilər
                        </NavLink>
                    </li>
                ) : null
                }
                {/* <li>
                    <NavLink to="/logout" activeClassName={styles.active}>
                        Çıxış et
                    </NavLink>
                </li> */}
            </ul>
        </div>
    );
};

export default Sidebar;
