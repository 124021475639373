import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styles from './style.module.css';

const CouponsPage = () => {
    const { campaignId, businessId } = useParams();
    const navigate = useNavigate();

    const [couponData, setCouponData] = useState({
        business_id: businessId || '',
        coupon_name: {
            az: '',
            en: ''
        },
        description: {
            az: '',
            en: ''
        },
        max_uses: 0,
        start_date: '',
        end_date: '',
        expiration: ''
    });

    useEffect(() => {
        if (campaignId) {
            fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/coupons/${campaignId}`)
                .then((response) => response.json())
                .then((data) => {
                    setCouponData({
                        business_id: data.business_id,
                        coupon_name: {
                            az: data.coupon_name.az,
                            en: data.coupon_name.en
                        },
                        description: {
                            az: data.description.az,
                            en: data.description.en
                        },
                        max_uses: data.max_uses,
                        expiration: data.expiration
                    });
                })
                .catch((error) => {
                    console.error('Error fetching coupon data:', error);
                });
        }
    }, [campaignId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCouponData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleNestedChange = (e, parentKey) => {
        const { name, value } = e.target;
        setCouponData((prevData) => ({
            ...prevData,
            [parentKey]: {
                ...prevData[parentKey],
                [name]: value
            }
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const url = campaignId
            ? `https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/coupons/${campaignId}`
            : `https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/coupons/`;

        const method = campaignId ? 'PATCH' : 'POST';

        fetch(url, {
            method: method,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(couponData)
        })
            .then((response) => {
                if (response.ok) {
                    console.log('Coupon saved successfully');
                    navigate('/dashboard');
                } else {
                    console.error('Error saving coupon');
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    return (
        <div className={styles.couponsPage}>
            <h2>{campaignId ? 'Edit Coupon' : 'Create Coupon'}</h2>
            <form onSubmit={handleSubmit} className={styles.couponForm}>
                <div className={styles.formGroup}>
                    <label htmlFor="business_id">Business ID</label>
                    <input
                        type="text"
                        id="business_id"
                        name="business_id"
                        value={couponData.business_id}
                        onChange={handleChange}
                        readOnly={!!campaignId}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="coupon_name_az">Coupon Name (AZ)</label>
                    <input
                        type="text"
                        id="coupon_name_az"
                        name="az"
                        value={couponData.coupon_name.az}
                        onChange={(e) => handleNestedChange(e, 'coupon_name')}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="coupon_name_en">Coupon Name (EN)</label>
                    <input
                        type="text"
                        id="coupon_name_en"
                        name="en"
                        value={couponData.coupon_name.en}
                        onChange={(e) => handleNestedChange(e, 'coupon_name')}
                    />
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="description_az">Description (AZ)</label>
                    <textarea
                        id="description_az"
                        name="az"
                        value={couponData.description.az}
                        onChange={(e) => handleNestedChange(e, 'description')}
                    ></textarea>
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="description_en">Description (EN)</label>
                    <textarea
                        id="description_en"
                        name="en"
                        value={couponData.description.en}
                        onChange={(e) => handleNestedChange(e, 'description')}
                    ></textarea>
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="max_uses">Max Uses</label>
                    <input
                        type="number"
                        id="max_uses"
                        name="max_uses"
                        value={couponData.max_uses}
                        onChange={handleChange}
                    />
                </div>
                {!campaignId && (
                    <>
                        <div className={styles.formGroup}>
                            <label htmlFor="start_date">Start Date</label>
                            <input
                                type="date"
                                id="start_date"
                                name="start_date"
                                value={couponData.start_date}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.formGroup}>
                            <label htmlFor="end_date">End Date</label>
                            <input
                                type="date"
                                id="end_date"
                                name="end_date"
                                value={couponData.end_date}
                                onChange={handleChange}
                            />
                        </div>
                    </>
                )}
                {campaignId && (
                    <div className={styles.formGroup}>
                        <label htmlFor="expiration">Expiration Date</label>
                        <input
                            type="date"
                            id="expiration"
                            name="expiration"
                            value={couponData.expiration}
                            onChange={handleChange}
                        />
                    </div>
                )}
                <button type="submit" className="btn btn-primary">
                    {campaignId ? 'Update Coupon' : 'Create Coupon'}
                </button>
            </form>
        </div>
    );
};

export default CouponsPage;
