import React, { useState } from 'react';
// import { Modal, Text, TextInput, TouchableOpacity, View, ActivityIClientResetPasswordPagendicator } from 'react-native'; // Import necessary components
import styles from './style.module.css'; // Import your styles from style.module.css

export default function ResetPassword() {
    const [email, setEmail] = useState('');
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleResetPassword = () => {
        // Check if all fields are filled
        if (email === '') {
            alert('Bütün xanalar doldurulmalıdır');
            return;
        }

        setIsLoading(true);

        // Create a JSON object with email
        const data = {
            email: email,
        };

        fetch('https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === 200) {
                    setSuccessModalVisible(true);
                } else {
                    setErrorModalVisible(true);
                }

                // Hide the modals after 3 seconds
                setTimeout(() => {
                    setSuccessModalVisible(false);
                    setErrorModalVisible(false);
                }, 3000);
            })
            .catch((error) => {
                setErrorModalVisible(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className={styles.container}>
            <div className={styles.loginContainer}>
                <p className={styles.title}>Şifrəni Yenilə</p>
                <input
                    className={styles.input}
                    placeholder="E-poçt ünvanı"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button className={styles.loginButton} onClick={handleResetPassword} disabled={isLoading}>
                    {isLoading ? (
                        <p>Göndərilir...</p>
                    ) : (
                        <p className={styles.loginButtonText}>Göndər</p>
                    )}
                </button>
            </div>

            {/* <Modal
                animationType="slide"
                transparent={true}
                visible={successModalVisible || errorModalVisible}
                onRequestClose={() => {
                    setSuccessModalVisible(false);
                    setErrorModalVisible(false);
                }}
            >
                <View className={styles.errorModal}>
                    <Text className={styles.errorText}>
                        {successModalVisible
                            ? 'Şifrəni Yeniləmək üçün e-poçt ünvanına məktub göndərildi.'
                            : errorModalVisible
                                ? 'Xəta baş verdi'
                                : ''}
                    </Text>
                </View>
            </Modal> */}
        </div>
    );
}
