import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './style.module.css';
import axios from 'axios';

export default function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // check if user is already logged in
    if (localStorage.getItem('user')) {
        window.location.replace('/app/explore');
    }

    const handleLogin = () => {
        if (email === '' || password === '') {
            alert('Bütün xanalar doldurulmalıdır');
            return;
        }

        setIsLoading(true);

        const data = {
            email: email,
            password: password,
            is_merchant: false
        };

        axios.post('https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/login', data)
            .then((response) => {
                console.log('API Response:', response.data);

                if (response.data.id) {
                    // Handle storing user data in web storage (localStorage, sessionStorage, etc.) as needed
                    // Example: localStorage.setItem('user', JSON.stringify(response.data));
                    // Note: Ensure proper security measures for storing sensitive user data
                    // Navigate to another screen or perform other actions upon successful login
                    localStorage.setItem('user', JSON.stringify(response.data));
                    window.location.replace('/app/explore');

                } else {
                    setErrorModalVisible(true);

                    setTimeout(() => {
                        setErrorModalVisible(false);
                    }, 3000);
                }
            })
            .catch((error) => {
                setErrorModalVisible(true);

                setTimeout(() => {
                    setErrorModalVisible(false);
                }, 3000);
                console.error('API Request Error:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <div className={styles.container}>
            <div className={styles.loginContainer}>
                <h1 className={styles.title}>Daxil Ol</h1>
                <input
                    className={styles.input}
                    type="text"
                    placeholder="E-poçt ünvanı"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    className={styles.input}
                    type="password"
                    placeholder="Şifrə"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button className={styles.loginButton} onClick={handleLogin} disabled={isLoading}>
                    {isLoading ? (
                        <span>Yüklənir...</span>
                    ) : (
                        <span>Giriş</span>
                    )}
                </button>
                <Link to="/app/register" className={styles.registerLink}>
                    Hesabın yoxdur? Qeydiyyatdan keç
                </Link>
                <br></br>
                <Link to="/app/reset-password" className={styles.registerLink}>
                    Şifrəni unutmusan?
                </Link>
            </div>

            {errorModalVisible && (
                <div className={styles.errorModal}>
                    <p className={styles.errorText}>E-poçt və ya şifrə yanlışdır. Yenidən cəhd et.</p>
                </div>
            )}
        </div>
    );
}
