import React, { useState, useEffect } from 'react';
import styles from './style.module.css';
import Sidebar from '../components/sidebar';
import { useLocation, Link } from 'react-router-dom';

const UsersPage = () => {
    const location = useLocation();
    const data = location.state.data;
    const [users, setUsers] = useState([]);
    const businessId = data.admin_id;
    const [activationStatus, setActivationStatus] = useState({ success: false, error: null });
    const [adminAssignmentStatus, setAdminAssignmentStatus] = useState({ success: false, error: null });
    const [chosenUserId, setChosenUserId] = useState(null);
    const [showAssignAdminModal, setShowAssignAdminModal] = useState(false);
    const [businessIdInput, setBusinessIdInput] = useState('');

    useEffect(() => {
        // Fetch user data when the component mounts
        fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/users/qzqsMaIP0tOXNQVzQ4YmrchTVB42`)
            .then((response) => response.json())
            .then((result) => {
                // Update the state with the API data
                setUsers(result.users);
            })
            .catch((error) => {
                console.error('Error fetching user data:', error);
            });
    }, [businessId]);

    const activateUser = (userId, email) => {
        // Send an API request to activate the user
        fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/user/activate/${userId}`, {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((result) => {
                // Check the response for success
                if (result.message === 'User activated successfully!') {
                    setActivationStatus({ success: true, error: null });
                    // Update the user's status in the local state
                    setUsers((prevUsers) =>
                        prevUsers.map((user) =>
                            user.id === userId ? { ...user, is_active: true } : user
                        )
                    );

                    // Send an email to the user with mailto
                    window.location.href = `mailto:${email}?subject=Hesabın Aktivləşdirildi-noreply&body=Əziz Dost, %0D%0A%0D%0A Tələbə statusun komandamız tərəfindən təsdiqləni və Qroovee hesabın aktivləşdirildi. Artıq Qroovee tətbiqindəki bütün imkanlardan istifadə edə bilərsən. %0D%0A%0D%0A Hörmətlə, %0D%0A%0D%0A Sənin Qroovee-dəki dostların`;
                } else {
                    setActivationStatus({ success: false, error: 'Activation failed.' });
                }
                // Set a timeout to clear the activation status after 2 seconds
                setTimeout(() => {
                    setActivationStatus({ success: false, error: null });
                }, 2000);
            })
            .catch((error) => {
                console.error('Error activating user:', error);
                setActivationStatus({ success: false, error: 'Activation failed.' });
                // Set a timeout to clear the activation status after 2 seconds
                setTimeout(() => {
                    setActivationStatus({ success: false, error: null });
                }, 2000);
            });
    };

    const assignAdminRole = () => {
        // Send an API request to assign admin role
        fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/assign-admin`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user_id: chosenUserId,
                business_id: businessIdInput,
            }),
        })
            .then((response) => response.json())
            .then((result) => {
                // Check the response for success
                if (result.message === 'User made admin successfully!') {
                    setAdminAssignmentStatus({ success: true, error: null });
                    // Optionally, you can update the user's role in the local state if needed
                } else {
                    setAdminAssignmentStatus({ success: false, error: 'Admin assignment failed.' });
                }
                // Set a timeout to clear the admin assignment status after 2 seconds
                setTimeout(() => {
                    setAdminAssignmentStatus({ success: false, error: null });
                }, 2000);
                // Close the modal
                setShowAssignAdminModal(false);
            })
            .catch((error) => {
                console.error('Error assigning admin role:', error);
                setAdminAssignmentStatus({ success: false, error: 'Admin assignment failed.' });
                // Set a timeout to clear the admin assignment status after 2 seconds
                setTimeout(() => {
                    setAdminAssignmentStatus({ success: false, error: null });
                }, 2000);
                // Close the modal
                setShowAssignAdminModal(false);
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.sidebarContainer}>
                <Sidebar data={data} />
            </div>
            <div className={styles.mainContent}>
                <div className={styles.header}>
                    <h1>İstifadəçilər</h1>
                    <div>
                        {activationStatus.success && <p className="alert alert-success">User activated successfully</p>}
                        {activationStatus.error && <p className="alert alert-danger">{activationStatus.error}</p>}
                        {adminAssignmentStatus.success && (
                            <p className="alert alert-success">Admin role assigned successfully</p>
                        )}
                        {adminAssignmentStatus.error && (
                            <p className="alert alert-danger">{adminAssignmentStatus.error}</p>
                        )}
                    </div>
                    <table className={styles.campaignTable}>
                        <thead>
                            <tr>
                                <th>İstifadəçi ID</th>
                                <th>İstifadəçi adı</th>
                                <th>İstifadəçi emaili</th>
                                <th>İstifadəçi nömrəsi</th>
                                <th>Tənzimləmələr</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user.id}>
                                    <td>{user.id}</td>
                                    <td>{user.first_name + ' ' + user.last_name}</td>
                                    <td>{user.email}</td>
                                    <td>{user.phone_number}</td>
                                    <td>
                                        {user.is_active === false ? (
                                            <button className="btn btn-primary" onClick={() => activateUser(user.id, user.email)}>
                                                Activate
                                            </button>
                                        ) : null}
                                        <button
                                            className="btn btn-success"
                                            onClick={() => {
                                                setShowAssignAdminModal(true);
                                                setChosenUserId(user.id);
                                            }}
                                        >
                                            Assign Admin Role
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Assign Admin Role Modal */}
            {showAssignAdminModal && (
                <div className={styles.modal}>
                    <div className={styles.modalContent}>
                        <span className={styles.close} onClick={() => setShowAssignAdminModal(false)}>
                            &times;
                        </span>
                        <h2>Assign Admin Role</h2>
                        <label htmlFor="businessId">Enter Business ID:</label>
                        <input
                            type="text"
                            id="businessId"
                            placeholder="Enter Business ID"
                            value={businessIdInput}
                            onChange={(e) => setBusinessIdInput(e.target.value)}
                        />
                        <button className="btn btn-primary" onClick={assignAdminRole}>
                            Submit
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UsersPage;
