import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';

function checkIconRendering(props) {
    if (props.isFA5) {
        return <i className={props.icon} style={{ color: "#F16623", fontSize: "1.5rem" }}></i>;
    } else {
        return <i className={props.icon} style={{ color: "#F16623", fontSize: "1.5rem" }}></i>;
    }
}

function Category(props) {
    return (
        <div className="category" style={styles.category}>
            {/* Category Icon */}
            <div
                className="categoryIcon"
                style={styles.categoryIcon}
                onClick={props.onPress}
            >
                {checkIconRendering(props)}
            </div>

            {/* Category Text */}
            <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 12 }}>{props.name}</p>
        </div>
    );
}

const styles = {
    category: {
        flex: 1,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 10,
        marginRight: 10,
    },
    categoryIcon: {
        backgroundColor: '#EEEEEE',
        borderRadius: '50%',
        height: 70,
        width: 70,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 5,
    },
};

export default Category;
