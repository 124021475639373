import React from 'react';
import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';
import LoginPage from './pages/admin/LoginPage';
import LandingPage from './pages/LandingPage';
import ClientAccountRemovePage from './pages/AccountDeletePage';

import DashboardPage from './pages/admin/DashboardPage';
import CampaignsPage from './pages/admin/CampaignsPage';
import UsersPage from './pages/admin/UsersPage';
import BusinessFormPage from './pages/admin/BusinessFormPage';
import ServicesFormPage from './pages/admin/ServicesFormPage';
import FormPage from './pages/admin/FormPage';
import PrivacyPage from './pages/admin/PrivacyPage';
import CouponsPage from './pages/admin/CouponsPage';


// Client Pages
import ClientAuthPage from './pages/client/auth/AuthPage';
import ClientLoginPage from './pages/client/auth/LoginPage';
import ClientRegisterPage from './pages/client/auth/RegisterPage';
import ClientResetPasswordPage from './pages/client/auth/ResetPassword';
import ClientExplorePage from './pages/client/ExplorePage';
import ClientListingPage from './pages/client/ListingPage';
// import ClientMapPage from './pages/client/MapPage';
import ClientSettingsPage from './pages/client/SettingsPage';
import ClientRedeemPage from './pages/client/RedeemPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<LandingPage />}
        />

        <Route
          path="/account-remove"
          element={<ClientAccountRemovePage />}
        />

        <Route
          path="/app/auth"
          element={<ClientAuthPage />}
        />

        <Route
          path="/app/login"
          element={<ClientLoginPage />}
        />

        <Route
          path="/app/register"
          element={<ClientRegisterPage />}
        />

        <Route
          path="/app/reset-password"
          element={<ClientResetPasswordPage />}
        />

        <Route
          path="/app/explore"
          element={<ClientExplorePage />}
        />

        <Route
          path="/app/settings"
          element={<ClientSettingsPage />}
        />

        {/* <Route
          path="/app/map"
          element={<ClientMapPage />}
        /> */}


        <Route
          path="/app/listing/:listingId"
          element={<ClientListingPage />}
        />

        <Route
          path="/app/redeem/:campaignId"
          element={<ClientRedeemPage />}
        />

        <Route
          path="/g4dhujRzW1HRg8fE6hGs"
          element={<LoginPage />}
        />

        <Route
          path="/campaigns"
          element={<CampaignsPage />}
        />

        <Route
          path="/dashboard"
          element={<DashboardPage />}
        />

        <Route
          path="/users"
          element={<UsersPage />}
        />

        <Route
          exact
          path="/privacy-policy"
          element={<PrivacyPage />}
        />

        <Route
          path="/new-coupon"
          element={<FormPage />}
        />

        <Route
          path="/new-business"
          element={<BusinessFormPage />}
        />

        <Route
          path="/edit-business/:businessId"
          element={<BusinessFormPage />}
        />

        <Route
          path="/edit-services/:businessId"
          element={<ServicesFormPage />}
        />


        <Route
          path="/edit-campaign/:campaignId"
          element={<CouponsPage />}
        />

        <Route
          path="/create-campaign/:businessId"
          element={<CouponsPage />}
        />



      </Routes>
    </Router>
  );
}

export default App;
