// ExplorePage.js
import React, { useState, useEffect } from 'react';
import Category from '../components/Category';
import Listing from '../components/Listing';
// import Listing from '../components/Listing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThList } from '@fortawesome/free-solid-svg-icons';
import { useHistory, Link } from 'react-router-dom';
import styles from './style.module.css'; // Import your styles from style.module.css

const ExplorePage = () => {

    const [categories, setCategories] = useState([]);
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState(''); // New state for search query
    const [selectedCategory, setSelectedCategory] = useState(null); // New state to track the selected category
    // const navigation = useNavigation();
    // const { isAuthenticated } = useAuth();

    if (!localStorage.getItem('user')) {
        window.location.replace('/app/auth');
    }

    useEffect(() => {
        setLoading(true);
        fetch('https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/getExploreListings', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                const { categories, ...restData } = data.data;
                setCategories(categories);
                setListings(restData);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const filteredListings = Object.keys(listings).filter((key) => {
        const listing = listings[key];
        const businessName = listing.business_name.az.toLowerCase();
        return businessName.includes(searchQuery.toLowerCase()) && (!selectedCategory || listing.category_id === selectedCategory);
    });

    const handleCategoryClick = (categoryId) => {
        setSelectedCategory(categoryId);
    };


    return (
        <div className={styles.container}>
            <div className={styles.topWrapper}>
                <div className={styles.topArea}>
                    <div className={styles.searchArea}>
                        <input
                            type="text"
                            className={styles.searchInput}
                            placeholder="Məkan axtar"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />

                    </div>

                </div>
            </div>

            <div className={styles.searchAndCategoriesBar}>
                <Category
                    key={0}
                    name={"Hamısı"}
                    icon={"fa-solid fa-th-list"}
                    isFA5={true}
                    onPress={() => handleCategoryClick(0)}
                />
                {categories.map((category, index) => (
                    <Category
                        key={category.id}
                        name={category.az}
                        icon={category.web}
                        isFA5={category.isFA5}
                        onPress={() => handleCategoryClick(category.id)}
                    />
                ))}
            </div>

            <div className={styles.listingsWrapper}>
                <div className={styles.businessListings}>
                    {filteredListings.length === 0 ? (
                        <p style={{ textAlign: "center", marginTop: 30, fontSize: 17 }}>Hal-hazırda bu kateqoriyaya məxsus biznes yoxdur.</p>
                    ) : (
                        <div>
                            {filteredListings.map((key) => {
                                const listing = listings[key];
                                return (
                                    <div
                                        key={key}
                                        onClick={() => {
                                            window.location.href = `listing/${key}`;
                                        }}
                                    >
                                        <Listing
                                            key={key}
                                            category={listing.category_id}
                                            image={listing.logo}
                                            name={listing.business_name.az}
                                            rating={listing.rating}
                                            coupons={listing.coupons}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>

            <div className={styles.bottomNavBar}>
                <Link
                    to="/app/explore"
                    className={styles.bottomBarButton}
                >
                    <button>
                        <i className='fa-solid fa-search'></i>
                    </button>
                    <p>Kəşf et</p>
                </Link>


                {/* <Link
                    to="/app/map"
                    className={styles.bottomBarButton}
                >
                    <button >
                        <i className="fa-solid fa-location-dot"></i>
                    </button>
                    <p>Xəritə</p>

                </Link> */}

                <Link
                    to="/app/settings"
                    className={styles.bottomBarButton}
                >
                    <button>
                        <i className='fa-solid fa-user'></i>
                    </button>
                    <p>İstifadəçi</p>

                </Link>
            </div>
        </div>
    );
};

export default ExplorePage;
