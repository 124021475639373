// Listing.js
import React, { useState } from 'react';

const Listing = (props) => {
    const [loading, setLoading] = useState(true);

    return (
        <div style={styles.listing}>
            {/* Listing Image */}
            <div style={styles.listingImage}>
                <img
                    src={props.image}
                    style={styles.listingPhoto}
                    onLoad={() => setLoading(false)}
                    alt="Listing"
                />
                {loading && (
                    <div style={styles.loadingIndicator}>
                        <div style={styles.activityIndicator}></div>
                    </div>
                )}
            </div>

            {/* Listing tags as buttons on bottom-left corner of photo */}
            <div style={styles.tags}>
                {props.coupons.map((coupon, index) => (
                    <div key={index} style={styles.tag}>
                        <span style={{ fontSize: 12, fontWeight: "bold" }}>{coupon.name.az}</span>
                    </div>
                ))}
            </div>

            {/* Listing Rating */}
            <div style={styles.infoBar}>
                <span style={{ fontWeight: "bold", fontSize: 16 }}>{props.name}</span>
                <div style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", marginLeft: "auto" }}>
                    <span style={styles.starIcon}>⭐</span>
                    <span style={{ textAlign: "center", fontWeight: "bold", fontSize: 12 }}>{props.rating}</span>
                </div>
            </div>

            {/* Listing Like Button */}
            {/* <div className={styles.likeButton}>
                <span>❤️</span>
            </div> */}
        </div>
    );
}

const styles = {
    listing: {
        flex: 1,
        alignSelf: 'stretch',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 5,
        marginRight: 5,
        marginTop: 10,
        marginBottom: 10,
    },
    listingImage: {
        height: 150,
        width: '100%',
        marginBottom: 5,
    },
    listingPhoto: {
        width: '100%',
        height: '100%',
        objectFit: "cover",
        borderRadius: 10
    },
    tags: {
        marginTop: 5,
        marginBottom: 5,
        display: "flex",
        flexDirection: "row",
        width: "100%"
    },
    tag: {
        backgroundColor: "#f5f5f5",
        borderRadius: 5,
        padding: 5,
        marginRight: 5,
    },
    infoBar: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignSelf: "stretch",
        paddingLeft: 5,
        paddingRight: 5
    },
    starIcon: {
        fontSize: 12,
        color: "orange",
        marginRight: 2,
    },
    loadingIndicator: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    activityIndicator: {
        border: "4px solid #f3f3f3",
        borderTop: "4px solid #3498db",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        animation: "spin 1s linear infinite",
    },
    '@keyframes spin': {
        '0%': { transform: 'rotate(0deg)' },
        '100%': { transform: 'rotate(360deg)' },
    },
};

export default Listing;
