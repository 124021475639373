import React, { useState, useEffect } from 'react';
import styles from './style.module.css';
import { Link } from 'react-router-dom';

export default function RegisterPage() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailConfirmation, setEmailConfirmation] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [birthdate, setBirthdate] = useState(null);
    const [graduationDate, setGraduationDate] = useState(null);
    const [sex, setSex] = useState('');
    const [university, setUniversity] = useState('');
    const [degree, setDegree] = useState('');
    const [studentCardImage, setStudentCardImage] = useState(null);
    const [isRegistrationSuccessful, setRegistrationSuccessful] = useState(false);
    const [isErrorModalVisible, setErrorModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Calculate the date 13 years ago
    const thirteenYearsAgo = new Date();
    thirteenYearsAgo.setFullYear(thirteenYearsAgo.getFullYear() - 13);


    const universities = [
        'Azərbaycan Əmək və Sosial Münasibətlər Akademiyası',
        'Azərbaycan Dillər Universiteti',
        'Azərbaycan Dövlət Aqrar Universiteti',
        'Azərbaycan Dövlət Bədən Tərbiyəsi və İdman Akademiyası',
        'Azərbaycan Dövlət Dəniz Akademiyası',
        'Azərbaycan Dövlət İqtisad Universiteti',
        'Azərbaycan Dövlət Mədəniyyət və İncəsənət Universiteti',
        'Azərbaycan Dövlət Neft və Sənaye Universiteti',
        'Azərbaycan Dövlət Pedaqoji Universiteti',
        'Azərbaycan Dövlət Rəssamlıq Akademiyası',
        'Azərbaycan Kooperasiya Universiteti',
        'Azərbaycan Memarlıq və İnşaat Universiteti',
        'Azərbaycan Milli Konservatoriyası',
        'Azərbaycan Respublikası Daxili İşlər Nazirliyinin Polis Akademiyası',
        'Azərbaycan Respublikası DİN Daxili Qoşunlarının Ali Hərbi Məktəbi',
        'Azərbaycan Respublikası Dövlət Gömrük Komitəsinin Akademiyası',
        'Azərbaycan Respublikası Dövlət Sərhəd Xidməti Akademiyası',
        'Azərbaycan Respublikası Dövlət Təhlükəsizliyi Xidmətinin Heydər Əliyev adına Akademiyası',
        'Azərbaycan Texniki Universiteti',
        'Azərbaycan Texnologiya Universiteti',
        'Azərbaycan Tibb Universiteti',
        'Azərbaycan Turizm və Menecment Universiteti',
        'Bakı Ali Neft Məktəbi',
        'Bakı Avrasiya Universiteti',
        'Bakı Biznes Universiteti',
        'Bakı Dövlət Universiteti',
        'Bakı Mühəndislik Universiteti',
        'Bakı Qızlar Universiteti',
        'Bakı Slavyan Universiteti',
        'Bakı Xoreoqrafiya Akademiyası',
        'Daxili İşlər Nazirliyinin Polis Akademiyası',
        'Fövqəladə Hallar Nazirliyinin Akademiyası',
        'Gəncə Dövlət Universiteti',
        'Heydər Əliyev adına Azərbaycan Ali Hərbi Məktəbi',
        'M.V.Lomonosov adına Moskva Dövlət Universitetinin Bakı filialı',
        'Milli Aviasiya Akademiyası',
        'Naxçıvan Müəllimlər İnstitutu',
        'Naxçıvan Özəl Universiteti',
        'Naxçıvan Dövlət Universiteti',
        'Odlar Yurdu Universiteti',
        'Qərbi Kaspi Universiteti',
        'Sumqayıt Dövlət Universiteti',
        'Üzeyir Hacıbəyli adına Bakı Musiqi Akademiyası',
        'Xəzər Universiteti',
        'İ.M Seçenov adına Birinci Moskva Dövlət Tibb Universitetinin Bakı filialı',
        '“ADA” Universiteti',
        "Azərbaycan Rəssamlıq Akademiyası - İncəsənət Kolleci",
        "Azərbaycan Texniki Universiteti - Bakı Texniki Kolleci",
        "Azərbaycan Dövlət Neft və Sənaye Universiteti - Bakı Neft-Energetika Kolleci",
        "Bakı İdarəetmə və Texnologiya Kolleci",
        "Azərbaycan Dövlət İqtisad Universiteti - Azərbaycan Maliyyə-İqtisad Kolleci",
        "Bakı Qida Sənayesi Kolleci",
        "Bakı Dövlət Sosial İqtisadi Kolleci",
        "Azərbaycan Dövlət Pedaqoji Universiteti - Azərbaycan Dövlət Pedaqoji Kolleci",
        "Bakı Humanitar Kolleci",
        "Azərbaycan Milli Konservatoriyası - Musiqi Kolleci",
        "Sumqayıt Dövlət Universiteti - Sumqayıt Dövlət Texniki Kolleci",
        "Sumqayıt Musiqi Kolleci",
        "Gəncə Dövlət Regional Kolleci",
        "Gəncə Musiqi Kolleci",
        "Lənkəran Dövlət Humanitar Kolleci",
        "Şəki Dövlət Regional Kolleci",
        "Şəki Dövlət Texniki Kolleci",
        "Şirvan Dövlət İqtisadiyyat və Humanitar Kolleci",
        "Ağdaş Dövlət Humanitar Kolleci",
        "Ağdam Musiqi Kolleci",
        "Şamaxı Dövlət Regional Kolleci",
        "Şuşa Humanitar Kolleci",
        "Astara Pedaqoji Kolleci",
        "Ağdam Dövlət Sosial İqtisadi Kolleci",
        "Bakı Dövlət İqtisadiyyat və Humanitar Kolleci",
        "Göyçay Dövlət İdarəetmə və Texnologiya Kolleci",
        "Zaqatala Dövlət İdarəetmə və Texnologiya Kolleci",
        "Bərdə Dövlət İdarəetmə və Texnologiya Kolleci",
        "Qazax Dövlət Sosial İqtisadi Kolleci",
        "Tovuz Dövlət Sosial İqtisadi Kolleci",
        "Sabirabad Dövlət Sosial İqtisadi Kolleci",
        "Quba Dövlət Sosial İqtisadi Kolleci",
        "Azərbaycan Texniki Universiteti - Bakı Dövlət Rabitə və Nəqliyyat Kolleci",
        "Ağcabədi Pedaqoji Kolleci",
        "Azərbaycan Memarlıq və İnşaat Universiteti - İnşaat Kolleci",
        "İsmayıllı Dövlət Humanitar və Texnologiya Kolleci",
        "Masallı Dövlət Regional Kolleci",
        "Mingəçevir Turizm Kolleci",
        "1 nömrəli Bakı Tibb Kolleci",
        "2 nömrəli Bakı Tibb Kolleci",
        "Naxçıvan Tibb Kolleci",
        "Gəncə Tibb Kolleci",
        "Sumqayıt Tibb Kolleci",
        "Mingəçevir Tibb Kolleci",
        "Şəki Tibb Kolleci",
        "Lənkəran Tibb Kolleci",
        "Cəlilabad Tibb Kolleci",
        "Quba Tibb Kolleci",
        "Saatlı Tibb Kolleci",
        "Bakı Biznes və Kooperasiya Kolleci",
        "Azərbaycan Dövlət Dəniz Akademiyasının nəzdində Azərbaycan Dənizçilik Kolleci"
    ];

    // check if user is already logged in
    if (localStorage.getItem('user')) {
        window.location.replace('/app/explore');
    }

    const handleImagePicker = async () => {
        // open file picker   
        const filePicker = document.createElement('input');
        filePicker.type = 'file';
        filePicker.accept = 'image/*';
        filePicker.click();

        // wait for user to select a file
        await new Promise((resolve) => {
            filePicker.addEventListener('change', resolve);
        });

        // get the file
        const file = filePicker.files[0];

        // convert file to base64 string
        const reader = new FileReader();
        reader.readAsDataURL(file);
        await new Promise((resolve) => {
            reader.addEventListener('load', resolve);
        });

        // set the image
        setStudentCardImage({
            uri: reader.result,
            base64: reader.result.split(',')[1],
            type: file.type,
            name: file.name,
        });

    };

    // const isDateValid = (mm, dd, yyyy) => {
    //     // ... (date validation logic)
    // };

    // useEffect(() => {
    //     // ... (useEffect logic for date validation)
    // }, [birthdate, graduationDate]);

    const handleRegister = async () => {

        if (!email || !password || !firstName || !lastName || !phoneNumber || !birthdate || !sex || !university || !degree || !graduationDate || !studentCardImage) {
            // console.log({
            //     studentCardImage,
            //     email,
            //     password,
            //     firstName,
            //     lastName,
            //     phoneNumber,
            //     birthdate,
            //     sex,
            //     university,
            //     degree,
            //     graduationDate,
            // });
            alert('Bütün xanalar doldurulmalıdır');
            return;
        }

        if (email != emailConfirmation) {
            alert('E-poçt ünvanları eyni deyil');
            return;
        }

        if (password != passwordConfirmation) {
            alert('Şifrələr eyni deyil');
            return;
        }


        setIsLoading(true);
        const data = {
            email: email,
            password: password,
            first_name: firstName,
            last_name: lastName,
            phone_number: phoneNumber,
            birthdate: birthdate,
            sex: sex,
            university: university,
            degree: degree,
            graduation_date: graduationDate,
            studentCardImage: studentCardImage,
            is_merchant: false
        };

        // console.log(data);

        // check if all fields are filled with if statements

        try {
            const response = await fetch('https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });
            if (response.ok) {
                const responseData = await response.json();
                // console.log('Registration successful', responseData);
                setIsLoading(false);
                setRegistrationSuccessful(true);
            } else {
                setIsLoading(false);
                setErrorModalVisible(true);
                console.error('Registration failed');
                setTimeout(() => {
                    setErrorModalVisible(false);
                }, 3000);
            }
        } catch (error) {
            console.error('Registration failed');
        }

    };

    return (
        <div className={styles.container}>
            <div className={styles.registerContainer}>
                <h1 className={styles.title}>Qeydiyyatdan Keç</h1>
                <input
                    className={styles.input}
                    type="text"
                    placeholder="Ad"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <input
                    className={styles.input}
                    type="text"
                    placeholder="Soyad"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <input
                    className={styles.input}
                    type="text"
                    placeholder="Telefon nömrəsi"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <input
                    className={styles.input}
                    type="email"
                    placeholder="E-poçt ünvanı"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    className={styles.input}
                    type="email"
                    placeholder="E-poçt ünvanı təkrar"
                    value={emailConfirmation}
                    onChange={(e) => setEmailConfirmation(e.target.value)}
                />
                <input
                    className={styles.input}
                    type="password"
                    placeholder="Şifrə"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <input
                    className={styles.input}
                    type="password"
                    placeholder="Şifrə təkrar"
                    value={passwordConfirmation}
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
                <div className={styles.dateContainer}>
                    <div>
                        <p><b>Doğum tarixi</b></p>
                        <input
                            className={styles.input}
                            type="date"
                            placeholder="Doğum tarixi"
                            value={birthdate}
                            max={thirteenYearsAgo.toISOString().split('T')[0]} // Set maximum date to 13 years ago
                            onChange={(e) => setBirthdate(e.target.value)}
                        />
                    </div>

                    <div>
                        <p className={styles.textLeft}><b>Məzuniyyət tarixi</b></p>
                        <input
                            className={styles.input}
                            type="date"
                            placeholder="Məzuniyyət tarixi"
                            value={graduationDate}
                            min={new Date().toISOString().split('T')[0]}
                            onChange={(e) => setGraduationDate(e.target.value)}
                        />
                    </div>
                </div>

                <select
                    className={styles.input}
                    value={sex}
                    onChange={(e) => setSex(e.target.value)}
                >
                    <option value="" disabled selected>
                        Cinsiyyət seç
                    </option>
                    <option value={"Kişi"}>Kişi</option>
                    <option value={"Qadın"}>Qadın</option>
                </select>

                <select
                    className={styles.input}
                    value={university}
                    onChange={(e) => setUniversity(e.target.value)}
                >
                    <option value="" disabled selected>
                        Təhsil müəssisəni seç
                    </option>
                    {universities.map((university) => (
                        <option value={university}>{university}</option>
                    ))}
                </select>

                <select
                    className={styles.input}
                    value={degree}
                    onChange={(e) => setDegree(e.target.value)}
                >
                    <option value="" disabled selected>
                        Təhsil pilləsini seç
                    </option>
                    <option value={"Subbakalavr"}>Subbakalavr</option>
                    <option value={"Bakalavr"}>Bakalavr</option>
                    <option value={"Magistr"}>Magistr</option>
                    <option value={"Doktorantura"}>Doktorantura</option>
                </select>

                <button className={styles.uploadButton} onClick={handleImagePicker}>
                    Tələbə kartı fotosu seç
                    {studentCardImage && <span className={styles.checkIcon}>✓</span>}
                </button>

                {studentCardImage && (
                    <img
                        src={studentCardImage.uri}
                        alt="Student Card"
                        className={styles.studentCardImage}
                    />
                )}

                <button className={styles.blackBtn} onClick={handleRegister}>
                    {isLoading ? (
                        <span className={styles.registerButtonText}>Yüklənir...</span>
                    ) : (
                        <span className={styles.registerButtonText}>Qeydiyyatdan keç</span>
                    )}
                </button>

                <p className={styles.privacyText}>
                    Qeydiyyatdan keçməklə{' '}
                    <Link target="_blank" style={{ color: "blue", textDecoration: "underline" }} to="https://qroovee.com/privacy-policy">Gizlilik siyasətimizi</Link> qəbul etmiş olursan.
                </p>


                {isRegistrationSuccessful && (
                    <div className={styles.modalContainer}>
                        <p className={styles.modalText}>
                            <span style={{ fontWeight: "bold", fontSize: 24, width: "100%" }}>Qeydiyyat uğurla tamamlandı! </span>
                            Komandamız tələbə olduğunu təsdiqlədikdən sonra xidmətimizdən fasiləsiz yararlana biləcəksən. Bizdən xəbər gözlə! 🤗
                        </p>

                        {/* Close button for this modal */}
                        <button className={styles.blackBtn} style={{ width: "80%", marginLeft: 'auto', marginRight: 'auto' }} onClick={() => {
                            setRegistrationSuccessful(false);
                        }}>
                            Bağla
                        </button>
                    </div>
                )}

                <Link to="/app/login" className={styles.loginLink}>
                    Hesabın var? Daxil ol
                </Link>
            </div>
        </div>
    );
}