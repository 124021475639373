// PrivacyPolicyPage.js
import React from 'react';
import styles from './style.module.css'

const PrivacyPolicyPage = () => {
    window.location.href = "/privacy.html";
    return <></>;
};

export default PrivacyPolicyPage;
