import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
// import AsyncStorage from 'react-native-async-storage/async-storage';

const Campaign = (props) => {
    const [userData, setUserData] = useState({});
    const navigate = useNavigate();

    // useEffect(() => {
    //     AsyncStorage.getItem('user')
    //         .then((user) => {
    //             setUserData(JSON.parse(user));
    //         });
    // }, []);

    const campaign = props.campaign;

    return (
        <div style={styles.campaign}>
            <p style={styles.campaignTitle}>{campaign.name.az}</p>
            <div style={styles.accordionContent}>
                <p style={styles.campaignDescription}>{campaign.description.az}</p>
                <Link
                    to={`/app/redeem/${campaign.id}`}
                    state={{
                        campaignId: campaign.id,
                        userId: userData.id,
                        maxUses: campaign.max_uses,
                        campaignName: campaign.name.az,
                    }}
                >
                    <button style={styles.campaignButton}>
                        Yararlan
                    </button>
                </Link>
            </div>
        </div>
    );
};

const styles = {
    campaign: {
        backgroundColor: "white",
        margin: "10px",
        padding: "10px",
        borderRadius: "10px",
        border: "1px solid black",
        boxShadow: "5px 5px 5px rgba(0, 0, 0, 0.1)",
    },
    campaignTitle: {
        fontSize: "18px",
        fontWeight: "bold",
        marginTop: "10px",
        marginLeft: "10px",
    },
    campaignDescription: {
        fontSize: "15px",
        marginTop: "10px",
        marginLeft: "10px",
    },
    campaignButton: {
        backgroundColor: "#F16623",
        padding: "10px",
        borderRadius: "10px",
        alignItems: "center",
        marginTop: "10px",
        cursor: "pointer",
        color: "white",
        fontSize: "15px",
        fontWeight: "bold",
    },
};

export default Campaign;
