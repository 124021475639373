import React, { useState } from 'react';
import logo from './logo.png';
import styles from './style.module.css';

const AccountDeletePage = () => {
    const [reqSuccess, setReqSuccess] = useState(false);
    const [reqErr, setReqErr] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const handleSubmit = async (event) => {

        event.preventDefault();
        // Check if all fields are filled
        if (!event.target.email.value || !event.target.email_conf.value || event.target.reason.value == 0) {
            alert('Bütün xanaları doldurun!');
            return;
        }

        // Check if emails match
        if (event.target.email.value !== event.target.email_conf.value) {
            alert('E-poçt ünvanları uyğun deyil!');
            return;
        }

        // Assuming you have the form data available
        const data = {
            user_email: event.target.email.value,
            reason: event.target.reason.value,
        };

        try {
            setIsLoading(true);
            const response = await fetch('https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/remove-account-request', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const responseData = await response.json();
                console.log('Registration successful', responseData);
                // Clear form
                event.target.email.value = '';
                event.target.email_conf.value = '';
                event.target.reason.value = 0;

                setReqSuccess(true);
                setReqErr(false);
            } else {
                console.error('Registration failed');
                setReqSuccess(false);
                setReqErr(true);
            }
        } catch (error) {
            console.error('Registration failed', error);
            setReqSuccess(false);
            setReqErr(true);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    return (
        <div className={styles.container}>

            {/* Modal */}
            <div className={styles.modalOverlay}>
                <div className={styles.modal}>
                    <h2>Hesab Ləğvi Üçün Müraciət Formu</h2>
                    <p>Hesabının silinmə prosesini başlatmaq üçün aşağıdakı formu doldur.</p>

                    {/* Success message */}
                    {reqSuccess && (
                        <div className="alert alert-success" role="alert">
                            Müraciət formunu uğurla tamamladın! Komandamız 3 iş günü ərzində hesabını ləğv edəcək və bu barədə sənə e-poçt göndərəcək.
                        </div>
                    )}

                    {/* Error message */}
                    {reqErr && (
                        <div className="alert alert-danger" role="alert">
                            Xəta baş verdi! Yenidən cəhd et.
                        </div>
                    )}

                    <form onSubmit={handleSubmit}>

                        <input type="email" id="email" name="email" placeholder='E-poçt ünvanı *' />

                        <input type="email" id="email_conf" name="email_conf" required placeholder='E-poçt ünvanı təkrarı *' />

                        <select id="reason" name="reason" required>
                            <option value="0" disabled selected>Səbəb seç</option>
                            <option value="1">Mən artıq Qroovee istifadə etmirəm</option>
                            <option value="2">Mən Qroovee-dəki xidmətlərdən razı deyiləm</option>
                            <option value="3">Mən Qroovee-dəki xidmətlərdən istifadə etmək istəmirəm</option>
                            <option value="4">İstifadə etdiyim brendlər Qroovee-də yoxdur</option>
                            <option value="5">Digər</option>
                        </select>


                        <button type="submit" disabled={isLoading} className={isLoading ? 'disabled' : ''}>
                            {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Göndər'}
                        </button>
                    </form>
                </div>
            </div>

        </div>
    );
};

export default AccountDeletePage;
