import React from 'react';
import { Link } from 'react-router-dom'; // Use Link for navigation
import logoImage from "./logo.png";
import styles from './style.module.css'; // Import your styles from style.module.css

const AuthPage = () => {

    // check if user is already logged in
    if (localStorage.getItem('user')) {
        window.location.replace('/app/explore');
    }

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                {/* <h1 style={{ fontSize: 50, fontWeight: "bold" }}>Qroovyd</h1> */}
                <img src={logoImage} alt="Logo" className={styles.logoImage} />
                <p style={{ fontSize: 18, fontWeight: "bold", textAlign: "center", marginBottom: 30 }}>Qrooveeyə xoş gəlmisən! Tətbiqə daxil ol və endirimlərdən yararlan</p>

                <Link to="/app/login" className={`${styles.authButton} ${styles.btnEmpty}`}>
                    <span style={{ color: "#F16623", fontWeight: "bold" }}>Daxil ol</span>
                </Link>

                <Link to="/app/register" className={styles.authButton}>
                    <span style={{ color: "white", fontWeight: "bold" }}>Qeydiyyatdan keç</span>
                </Link>
            </div>
        </div>
    );
}

export default AuthPage;
