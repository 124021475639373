import React, { useState } from 'react';
import logo from './logo.png';
import appPhoto from './app.png';
import styles from './style.module.css';

const LandingPage = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isModalOpen, setModalOpen] = useState(false);
    const [registrationSuccessful, setRegistrationSuccessful] = useState(false);
    const [registrationError, setRegistrationError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);



    const handleMenuToggle = () => {
        setMenuOpen(!isMenuOpen);
    };

    const handleModalToggle = () => {
        setModalOpen(!isModalOpen);
        setRegistrationSuccessful(false);
        setRegistrationError(false);
    };

    const handleSubmit = async (event) => {

        event.preventDefault();

        // Check if all fields are filled
        if (!event.target.email.value || !event.target.password.value || !event.target.firstName.value || !event.target.lastName.value || !event.target.phone.value) {
            alert('Bütün xanaları doldurun!');
            return;
        }

        // Check if emails match
        if (event.target.email.value !== event.target.email_conf.value) {
            alert('E-poçt ünvanları uyğun deyil!');
            return;
        }

        // Check if passwords match
        if (event.target.password.value !== event.target.password_conf.value) {
            alert('Şifrələr uyğun deyil!');
            return;
        }


        // Assuming you have the form data available
        const data = {
            email: event.target.email.value,
            password: event.target.password.value,
            first_name: event.target.firstName.value,
            last_name: event.target.lastName.value,
            phone_number: event.target.phone.value,
            is_merchant: true
        };

        try {
            setIsLoading(true);
            const response = await fetch('https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/signup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const responseData = await response.json();
                console.log('Registration successful', responseData);
                // Clear form
                event.target.email.value = '';
                event.target.email_conf.value = '';
                event.target.password.value = '';
                event.target.password_conf.value = '';
                event.target.firstName.value = '';
                event.target.lastName.value = '';
                event.target.phone.value = '';

                setRegistrationSuccessful(true);
                setRegistrationError(false);
            } else {
                console.error('Registration failed');
                setRegistrationSuccessful(false);
                setRegistrationError(true);
            }
        } catch (error) {
            console.error('Registration failed', error);
            setRegistrationSuccessful(false);
            setRegistrationError(true);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    return (
        <div className={styles.container}>
            {/* navbar with logo and 'daxil ol', and 'müraciət et' */}
            <section className={styles.navbar}>
                <div className={styles.logoWrapper}>
                    <img src={logo} alt="Logo" />
                </div>
                <div className={`${styles.menuElementsWrapper} ${isMenuOpen ? styles.active : ''}`}>
                    <div className={styles.menuElement}>
                        <a onClick={handleModalToggle}>Müraciət et</a>
                    </div>
                    <div className={styles.menuElement}>
                        <a href='tel:+994507685097'>+994 50 768 5097</a>
                    </div>
                    <div className={styles.menuElement}>
                        <a href='mailto:contact@qroovee.com'>contact@qroovee.com</a>
                    </div>
                </div>
                <div className={[styles.menuButton, styles.bar].join(' ')} onClick={handleMenuToggle}>
                    <span>
                        ☰
                    </span>
                </div>
            </section>

            {/* Modal */}
            {isModalOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modal}>
                        <span className={styles.closeButton} onClick={handleModalToggle}>x</span>

                        <h2>Partnyorumuz ol</h2>
                        <p>Biznesinlə bizə qoşulmaq üçün aşağıdakı formu doldur. Komandamız səninlə əlaqə saxlayacaq.</p>

                        {/* Success message */}
                        {registrationSuccessful && (
                            <div className="alert alert-success" role="alert">
                                Müraciət formunu uğurla tamamladın! Bizdən xəbər gözlə. Komandamız tezliklə səninlə əlaqə saxlayacaq! 🤩
                            </div>
                        )}

                        {/* Error message */}
                        {registrationError && (
                            <div className="alert alert-danger" role="alert">
                                Xəta baş verdi! Yenidən cəhd et.
                            </div>
                        )}

                        <form onSubmit={handleSubmit}>
                            <input type="text" id="firstName" name="firstName" placeholder='Ad' />

                            <input type="text" id="lastName" name="lastName" placeholder='Soyad' />

                            <input type="tel" id="phone" name="phone" placeholder='Telefon nömrəsi' />

                            <input type="email" id="email" name="email" placeholder='E-poçt ünvanı' />

                            <input type="email" id="email_conf" name="email_conf" placeholder='E-poçt ünvanı təkrarı' />

                            <input type="password" id="password" name="password" placeholder='Şifrə' />

                            <input type="password" id="password_conf" name="password_conf" placeholder='Şifrə təkrarı' />


                            <p style={{ alignSelf: 'flex-start', marginTop: 5 }}>Qeydiyyatdan keçməklə <a style={{ color: '#007bff' }} target="_blank" href="https://qroovee.com/privacy-policy">Gizlilik siyasətimizi</a> qəbul etmiş olursan.</p>

                            <button type="submit" disabled={isLoading} className={isLoading ? 'disabled' : ''}>
                                {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Göndər'}
                            </button>
                        </form>
                    </div>
                </div>
            )
            }

            <section className={styles.info}>
                <div className={styles.texts}>
                    <h1>Azərbaycanın Endirim Platforması</h1>
                    <p>
                        Qroovee-də tələbə və ya korporativ statusunla, restorandan geyimə və əyləncəyə qədər olan özəl endirimlərdən
                        istifadə et və bənzərsiz təkliflərdən yararlan.
                    </p>
                    <div className={styles.buttons}>
                        <img
                            onClick={() => window.open('https://play.google.com/store/apps/details?id=com.qrooveeclient')}
                            src={
                                'https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/1200px-Google_Play_Store_badge_EN.svg.png'
                            }
                            alt="Google Play"
                        />
                        <img
                            onClick={() => window.open('https://apps.apple.com/us/app/qroovee/id6471628003')}
                            src={
                                'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/800px-Download_on_the_App_Store_Badge.svg.png'
                            }
                            alt="App Store"
                        />
                    </div>
                </div>
                <div className={styles.photoWrapper}>
                    <img src={appPhoto} alt="App Photo" />
                </div>
            </section>
        </div >
    );
};

export default LandingPage;
