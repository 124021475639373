import React, { useState, useEffect } from 'react';
import styles from './style.module.css';
import Sidebar from '../components/sidebar';
import { useLocation } from 'react-router-dom';

// Utility function to resize image and convert to base64
const resizeImage = (file, callback) => {
    const reader = new FileReader();
    reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set the canvas dimensions to 50% of the original image dimensions
            canvas.width = img.width * 0.5;
            canvas.height = img.height * 0.5;

            // Draw the image on the canvas
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            // Convert the canvas to base64 with lower quality
            const dataUrl = canvas.toDataURL('image/jpeg', 0.5);
            callback(dataUrl);
        };
        img.src = event.target.result;
    };
    reader.readAsDataURL(file);
};

const ServicesFormPage = () => {
    const location = useLocation();
    const businessId = location.pathname.split('/')[2];

    const [servicesData, setServicesData] = useState({
        services: [],
        sets: [],
    });

    const [isCreatingCategory, setIsCreatingCategory] = useState(false);
    const [isCreatingSet, setIsCreatingSet] = useState(false);

    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategoryItems, setNewCategoryItems] = useState([]);

    const [newSetName, setNewSetName] = useState('');
    const [newSetItems, setNewSetItems] = useState([]);
    const [newSetPrice, setNewSetPrice] = useState('');
    const [newSetPhoto, setNewSetPhoto] = useState(null);

    const handleAddCategoryItem = () => {
        setNewCategoryItems([...newCategoryItems, { name: '', price: '', discount_rate: '', photo: null }]);
    };

    const handleAddSetItem = () => {
        setNewSetItems([...newSetItems, { name: '' }]);
    };

    const handleCategoryItemChange = (index, field, value) => {
        const items = [...newCategoryItems];
        items[index][field] = value;
        setNewCategoryItems(items);
    };

    const handleSetItemChange = (index, field, value) => {
        const items = [...newSetItems];
        items[index][field] = value;
        setNewSetItems(items);
    };

    const handleFileChange = (index, event, type) => {
        const file = event.target.files[0];
        resizeImage(file, (dataUrl) => {
            if (type === 'category') {
                handleCategoryItemChange(index, 'photo', dataUrl);
            } else if (type === 'set') {
                setNewSetPhoto(dataUrl);
            }
        });
    };

    const handleCreateCategory = async () => {
        const newCategory = { category_name: newCategoryName, items: newCategoryItems };

        // Send POST request to create category
        try {
            const response = await fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/listing/services/create/${businessId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ services: [newCategory] }),
            });
            if (response.ok) {
                const data = await response.json();
                setServicesData(prevState => ({
                    ...prevState,
                    services: [...prevState.services, newCategory],
                }));
                setNewCategoryName('');
                setNewCategoryItems([]);
                setIsCreatingCategory(false);
            } else {
                console.error('Failed to create category');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleCreateSet = async () => {
        const newSet = { name: newSetName, items: newSetItems.map(item => item.name), price: newSetPrice, photo: newSetPhoto };

        // Send POST request to create set
        try {
            const response = await fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/listing/services/create/${businessId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ sets: [newSet] }),
            });
            if (response.ok) {
                const data = await response.json();
                setServicesData(prevState => ({
                    ...prevState,
                    sets: [...prevState.sets, newSet],
                }));
                setNewSetName('');
                setNewSetItems([]);
                setNewSetPrice('');
                setNewSetPhoto(null);
                setIsCreatingSet(false);
            } else {
                console.error('Failed to create set');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        if (businessId) {
            // Fetch services data from the backend
            fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/listings/services/${businessId}`)
                .then((response) => response.json())
                .then((data) => {
                    // Ensure data types are correct
                    const formattedData = {
                        services: (data.services || []).map(category => ({
                            ...category,
                            items: (category.items || []).map(item => ({
                                ...item,
                                price: parseFloat(item.price),
                                discount_rate: parseFloat(item.discount_rate)
                            }))
                        })),
                        sets: (data.sets || []).map(set => ({
                            ...set,
                            price: parseFloat(set.price)
                        }))
                    };
                    setServicesData(formattedData);
                });
        }
    }, [businessId]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.sidebarContainer}>
                <Sidebar />
            </div>
            <div className={styles.mainContent}>
                <button onClick={() => setIsCreatingCategory(!isCreatingCategory)}>Create Category</button>
                <button onClick={() => setIsCreatingSet(!isCreatingSet)}>Create Set</button>

                {isCreatingCategory && (
                    <div className={styles.form}>
                        <h2>Create New Category</h2>
                        <input
                            type="text"
                            placeholder="Category Name"
                            value={newCategoryName}
                            onChange={(e) => setNewCategoryName(e.target.value)}
                            className={styles.input}
                        />
                        {newCategoryItems.map((item, index) => (
                            <div key={index} className={styles.itemContainer}>
                                <input
                                    type="text"
                                    placeholder="Service Name"
                                    value={item.name}
                                    onChange={(e) => handleCategoryItemChange(index, 'name', e.target.value)}
                                    className={styles.input}
                                />
                                <input
                                    type="number"
                                    placeholder="Price"
                                    value={item.price}
                                    onChange={(e) => handleCategoryItemChange(index, 'price', e.target.value)}
                                    className={styles.input}
                                />
                                <input
                                    type="number"
                                    placeholder="Discount Rate"
                                    value={item.discount_rate}
                                    onChange={(e) => handleCategoryItemChange(index, 'discount_rate', e.target.value)}
                                    className={styles.input}
                                />
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleFileChange(index, e, 'category')}
                                    className={styles.input}
                                />
                            </div>
                        ))}
                        <button onClick={handleAddCategoryItem} className={styles.addButton}>Add Item</button>
                        <button onClick={handleCreateCategory} className={styles.saveButton}>Save Category</button>
                    </div>
                )}

                {isCreatingSet && (
                    <div className={styles.form}>
                        <h2>Create New Set</h2>
                        <input
                            type="text"
                            placeholder="Set Name"
                            value={newSetName}
                            onChange={(e) => setNewSetName(e.target.value)}
                            className={styles.input}
                        />
                        {newSetItems.map((item, index) => (
                            <div key={index} className={styles.itemContainer}>
                                <input
                                    type="text"
                                    placeholder="Item Name"
                                    value={item.name}
                                    onChange={(e) => handleSetItemChange(index, 'name', e.target.value)}
                                    className={styles.input}
                                />
                            </div>
                        ))}
                        <button onClick={handleAddSetItem} className={styles.addButton}>Add Item</button>
                        <input
                            type="number"
                            placeholder="Set Price"
                            value={newSetPrice}
                            onChange={(e) => setNewSetPrice(e.target.value)}
                            className={styles.input}
                        />
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleFileChange(null, e, 'set')}
                            className={styles.input}
                        />
                        <button onClick={handleCreateSet} className={styles.saveButton}>Save Set</button>
                    </div>
                )}

                <div className={styles.services}>
                    {servicesData.services.map((category, index) => (
                        <div key={index} className={styles.categoryContainer}>
                            <h3>{category.category_name}</h3>
                            {category.items.map((item, i) => (
                                <div key={i} className={styles.itemContainer}>
                                    <p><b>Name:</b> {item.name}</p>
                                    <p><b>Price:</b> {item.price}</p>
                                    <p><b>Discount Rate:</b> {item.discount_rate}%</p>
                                    <img src={item.photo} alt={item.name} className={styles.image} />
                                </div>
                            ))}
                        </div>
                    ))}

                    <h3>Sets</h3>
                    {servicesData.sets.map((set, index) => (
                        <div key={index} className={styles.setContainer}>
                            <p><b>Name:</b> {set.name}</p>
                            <p><b>Price:</b> {set.price}</p>
                            <img src={set.photo} alt={set.name} className={styles.image} />
                            <ul>
                                {set.items.map((item, i) => (
                                    <li key={i}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ServicesFormPage;
