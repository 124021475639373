import React, { useState, useEffect } from 'react';
import styles from './style.module.css';
import Sidebar from "../components/sidebar";
import { useLocation, Link } from 'react-router-dom';

const CampaignsPage = () => {
    const location = useLocation();
    const data = location.state.data;
    const [campaigns, setCampaigns] = useState(data.coupons);
    const [businessCampaigns, setBusinessCampaigns] = useState([]);
    const [confirmedDeletion, setConfirmedDeletion] = useState([]);
    const [activeButton, setActiveButton] = useState(null);
    const listing_id = localStorage.getItem('listing_id');
    const [businessId, setBusinessId] = useState('');

    const handleToggleConfirmation = (campaignId) => {
        if (confirmedDeletion.includes(campaignId)) {
            // Send delete request here
            fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/coupons/${campaignId}`, {
                method: 'DELETE',
            })
                .then((response) => {
                    if (response.status === 200) {
                        // Successful delete, remove the deleted campaign from the table
                        const updatedCampaigns = campaigns.filter(campaign => campaign.id !== campaignId);
                        setCampaigns(updatedCampaigns);
                        console.log(`Campaign with ID ${campaignId} deleted successfully.`);
                    } else {
                        // Handle error or display a message to the user.
                        console.error(`Error deleting campaign with ID ${campaignId}`);
                    }
                })
                .catch((error) => {
                    console.error('Error deleting campaign:', error);
                });
            setActiveButton(null);
        } else {
            setConfirmedDeletion([...confirmedDeletion, campaignId]);
            setActiveButton(campaignId);
        }
    };

    const fetchBusinessCampaigns = () => {
        console.log("fetching data for", businessId);
        fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/listings/${businessId}`)
            .then((response) => response.json())
            .then((result) => {
                // Update the state with the API data
                setBusinessCampaigns(result.coupons);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    useEffect(() => {
        const handleDocumentClick = (e) => {
            if (activeButton && !e.target.closest(`.${styles.actionButton}`)) {
                // If the user clicks anywhere else on the screen, reset the button state
                setConfirmedDeletion(confirmedDeletion.filter((id) => id !== activeButton));
                setActiveButton(null);
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [activeButton, confirmedDeletion]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.sidebarContainer}>
                <Sidebar data={data} />
            </div>
            <div className={styles.mainContent}>
                <div className={styles.header}>
                    <h1>Kampaniyalar</h1>
                    {listing_id !== "1" ? (
                        <>
                            <Link to="/new-coupon" className="btn btn-primary">
                                Yeni Kampaniya Yarat
                            </Link>
                            <hr />
                            <table className={styles.campaignTable}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Başlıq</th>
                                        <th>Başlanğıc tarixi</th>
                                        <th>Bitmə tarixi</th>
                                        <th>Tənzimləmələr</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {campaigns.length > 0 ? (
                                        campaigns.map((campaign) => (
                                            <tr key={campaign.id}>
                                                <td>{campaign.id}</td>
                                                <td>{campaign.name.az}</td>
                                                <td>{campaign.start_date}</td>
                                                <td>{campaign.end_date}</td>
                                                <td>
                                                    <Link to={`/edit-coupon/${campaign.id}`}>
                                                        <button className='btn btn-xs btn-primary'>
                                                            <i className="fa-solid fa-edit"></i>
                                                        </button>
                                                    </Link>
                                                    <button
                                                        className={`btn btn-xs ${confirmedDeletion.includes(campaign.id)
                                                            ? `btn-success ${styles.actionButton}`
                                                            : `btn-danger ${styles.actionButton}`
                                                            }`}
                                                        onClick={() => handleToggleConfirmation(campaign.id)}
                                                    >
                                                        {confirmedDeletion.includes(campaign.id) ? (
                                                            <i className="fa-solid fa-check"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-trash"></i>
                                                        )}
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5">Hal-hazırda aktiv kampaniya mövcud deyil</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </>
                    ) : (
                        <div>
                            <input
                                type="text"
                                placeholder="Business ID"
                                id="businessID"
                                value={businessId}
                                onChange={(e) => setBusinessId(e.target.value)}
                            />
                            <button className="btn btn-primary" onClick={fetchBusinessCampaigns}>
                                Axtar
                            </button>
                            <hr />
                            <table className={styles.campaignTable}>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Başlıq</th>
                                        <th>Başlanğıc tarixi</th>
                                        <th>Bitmə tarixi</th>
                                        {/* <th>Tənzimləmələr</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {businessCampaigns.length > 0 ? (
                                        businessCampaigns.map((campaign) => (
                                            <tr key={campaign.id}>
                                                <td>{campaign.id}</td>
                                                <td>{campaign.name.az}</td>
                                                <td>{campaign.start_date}</td>
                                                <td>{campaign.end_date}</td>
                                                {/* <td>
                                                    <Link to={`/edit-coupon/${campaign.id}`}>
                                                        <button className='btn btn-xs btn-primary'>
                                                            <i className="fa-solid fa-edit"></i>
                                                        </button>
                                                    </Link>
                                                    <button
                                                        className={`btn btn-xs ${confirmedDeletion.includes(campaign.id)
                                                            ? `btn-success ${styles.actionButton}`
                                                            : `btn-danger ${styles.actionButton}`
                                                            }`}
                                                        onClick={() => handleToggleConfirmation(campaign.id)}
                                                    >
                                                        {confirmedDeletion.includes(campaign.id) ? (
                                                            <i className="fa-solid fa-check"></i>
                                                        ) : (
                                                            <i className="fa-solid fa-trash"></i>
                                                        )}
                                                    </button>
                                                </td> */}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="5">Hal-hazırda aktiv kampaniya mövcud deyil</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CampaignsPage;
