import React, { useEffect, useState } from 'react';
import styles from './style.module.css';
import Sidebar from '../components/sidebar';
import { useLocation } from 'react-router-dom';
import { upload } from '@testing-library/user-event/dist/upload';

const BusinessFormPage = () => {
    const location = useLocation();

    const [editMode, setEditMode] = useState(false);

    // get the business id from the url
    const listingId = location.pathname.split('/')[2];
    console.log(listingId);
    const [formData, setFormData] = useState({
        business_name: {
            az: '',
        },
        rating: '',
        description: {
            az: '',
        },
        discount_tag: '',
        business_hours: '',
        social_media: {
            instagram: '',
            tiktok: '',
            facebook: '',
            website: '',
        },

        address: {
            full_address: '',
            latitude: 0,
            longitude: 0,
        },
        contact: '',
        photo: null, // Handle file upload for photo
        logo: null, // Handle file upload for logo
    });

    const [photoData, setPhotoData] = useState({
        photo: ''
    });

    useEffect(() => {
        if (listingId) {
            setEditMode(true);
            // fetch the listing data from the backend
            fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/listings/${listingId}`)
                .then((response) => response.json())
                .then((data) => {
                    setFormData({
                        business_name: {
                            az: data.business_name.az,
                        },
                        rating: data.rating,
                        description: {
                            az: data.description.az,
                        },
                        discount_tag: data.discount_tag,
                        other_tags: data.other_tags,
                        business_hours: data.business_hours,
                        social_media: {
                            instagram: data.social_media.instagram,
                            tiktok: data.social_media.tiktok,
                            facebook: data.social_media.facebook,
                            website: data.social_media.website,
                            youtube: data.social_media.youtube,
                            whatsapp: data.social_media.whatsapp,
                        },
                        address: {
                            full_address: data.address.full_address,
                            latitude: data.address.latitude,
                            longitude: data.address.longitude,
                        },
                        category: data.category_id,
                        admin_id: data.admin_id,
                        contact: data.contact.join(', '),
                        photo: data.photo, // Handle file upload for photo
                        logo: data.logo, // Handle file upload for logo
                    });
                });
        }
    }, [listingId]);


    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    console.log(formData);

    const removeImage = (image) => {
        const newPhotos = formData.photo.filter((ph) => ph !== image);
        fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/listings/${listingId}/remove-image`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ photo_url: image }),
        })
            .then((response) => response.json())
            .then(() => {
                alert('Image removed successfully');
                setFormData({ ...formData, photo: newPhotos });
            })
            .catch((error) => {
                setErrorMessage('An error occurred');
                // Handle the error case as needed
            });
    };

    const uploadImage = () => {
        if (!photoData.photo) {
            setErrorMessage('Please select a photo to upload');
            return;
        }
    
        const photoReader = new FileReader();
        photoReader.onload = () => {
            const base64Photo = photoReader.result.split(',')[1]; // Get the base64 part of the string
    
            fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/listings/${listingId}/upload-image`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ photo: {base64:base64Photo} }),
            })
                .then((response) => response.json())
                .then(() => {
                    alert('Image uploaded successfully');
                    setFormData({ ...formData, photo: [...formData.photo, base64Photo] });
                })
                .catch((error) => {
                    setErrorMessage('An error occurred during image upload');
                    console.error('Error uploading image:', error);
                });
        };
    
        // Check if photoData.photo is actually a file
        if (photoData.photo instanceof File) {
            photoReader.readAsDataURL(photoData.photo);
        } else {
            setErrorMessage('Invalid file selected. Please choose a valid image.');
        }
    };

    const handleSubmit = async () => {
        setIsSubmitting(true);

        // Convert photo and logo to base64 separately
        const photoReader = new FileReader();
        const logoReader = new FileReader();

        if (formData.photo) {
            photoReader.onload = () => {
                const base64Photo = photoReader.result;

                if (formData.logo) {
                    logoReader.onload = () => {
                        const base64Logo = logoReader.result;

                        const dataToSend = {
                            business_name: {
                                az: formData.business_name.az,
                            },
                            rating: formData.rating,
                            discount_tag: formData.discount_tag,
                            other_tags: formData.other_tags,
                            business_hours: formData.business_hours,
                            description: {
                                az: formData.description.az,
                            },
                            social_media: {
                                instagram: formData.social_media.instagram,
                                tiktok: formData.social_media.tiktok,
                                facebook: formData.social_media.facebook,
                                website: formData.social_media.website,
                                youtube: formData.social_media.youtube,
                                whatsapp: formData.social_media.whatsapp,
                            },
                            address: {
                                full_address: formData.address.full_address,
                                latitude: formData.address.latitude,
                                longitude: formData.address.longitude,
                            },
                            category_id: formData.category,
                            admin_id: formData.admin_id,
                            contact: formData.contact,
                            photo: base64Photo,
                            logo: base64Logo,
                        };

                        fetch('https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/listings/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(dataToSend),
                        })
                            .then((response) => response.json())
                            .then(() => {
                                setSuccessMessage('Business listing created successfully');
                                // Handle the success case as needed
                            })
                            .catch((error) => {
                                setErrorMessage('An error occurred');
                                // Handle the error case as needed
                            });
                    };
                    logoReader.readAsDataURL(formData.logo);
                } else {
                    setErrorMessage('Please select a logo');
                    setIsSubmitting(false);
                }
            };
            photoReader.readAsDataURL(formData.photo);
        } else {
            setErrorMessage('Please select a photo');
            setIsSubmitting(false);
        }
    };

    const handleUpdate = async () => {
        setIsSubmitting(true);

        // Prepare the data to send to the backend
        const dataToSend = {
            business_name: {
                az: formData.business_name.az,
            },
            rating: formData.rating,
            discount_tag: formData.discount_tag,
            other_tags: formData.other_tags,
            business_hours: formData.business_hours,
            description: {
                az: formData.description.az,
            },
            social_media: {
                instagram: formData.social_media.instagram,
                tiktok: formData.social_media.tiktok,
                facebook: formData.social_media.facebook,
                website: formData.social_media.website,
                youtube: formData.social_media.youtube,
                whatsapp: formData.social_media.whatsapp,
            },
            address: {
                full_address: formData.address.full_address,
                latitude: formData.address.latitude,
                longitude: formData.address.longitude,
            },
            logoPhoto: null,
            category_id: formData.category,
            contact: formData.contact,
        };

        // Check if formData.logo exists and is a file
        if (formData.logo instanceof File) {
            const logoReader = new FileReader();
            logoReader.onload = () => {
                const base64Logo = logoReader.result;
                // Set logoPhoto object
                dataToSend.logoPhoto = {
                    is_logo_changed: true,
                    logo: base64Logo,
                };

                // After setting logoPhoto, send the update request
                sendUpdateRequest(dataToSend);
            };
            logoReader.readAsDataURL(formData.logo);
        } else {
            // If formData.logo is not a file or is null/undefined, directly send the update request
            dataToSend.logoPhoto = {
                is_logo_changed: false,
                logo: null
            };
            sendUpdateRequest(dataToSend);
        }
    };

    const sendUpdateRequest = (dataToSend) => {
        fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/listings/${listingId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataToSend),
        })
            .then((response) => response.json())
            .then(() => {
                setSuccessMessage('Business listing updated successfully');
                // Handle the success case as needed
            })
            .catch((error) => {
                setErrorMessage('An error occurred');
                // Handle the error case as needed
            })
            .finally(() => {
                setIsSubmitting(false); // Reset the submitting state regardless of success or failure
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.sidebarContainer}>
                <Sidebar />
            </div>
            <div className={styles.mainContent}>
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                {successMessage && <div className="alert alert-success">{successMessage}</div>}

                <legend>Əsas məlumatlar</legend>

                <input
                    type="text"
                    placeholder="Business Name (Azərbaycanca)"
                    value={formData.business_name.az}
                    onChange={(e) => setFormData({ ...formData, business_name: { ...formData.business_name, az: e.target.value } })}
                />

                <input
                    type="number"
                    placeholder="Rating"
                    value={formData.rating}
                    onChange={(e) => setFormData({ ...formData, rating: e.target.value })}
                />

                <input
                    rows="5" cols="40"
                    placeholder="Business Description (Azərbaycanca)"
                    value={formData.description.az}
                    onChange={(e) => setFormData({ ...formData, description: { ...formData.description, az: e.target.value } })}
                />

                <label htmlFor="business_hours"><b>Qayda:</b> Həftənin hər bir günü üçün açılış və bağlanış saatları aşağıdakı nümunədəki kimi yazılmalıdır. <br /> <i>Nümunə:</i> 9:00-23:00<b>/</b>9:00-23:00<b>/</b>9:00-23:00<b>/</b>9:00-23:00<b>/</b>9:00-23:00<b>/</b>12:00-00:00<b>/</b>Bağlı</label>
                <input
                    rows="5" cols="40"
                    placeholder="İş Saatları"
                    value={formData.business_hours}
                    onChange={(e) => setFormData({ ...formData, business_hours: e.target.value })}
                />

                <label htmlFor="discount_tag"><i>Nümunə:</i> -50%</label>
                <input
                    type="text"
                    placeholder="Endirim teqi"
                    value={formData.discount_tag}
                    onChange={(e) => setFormData({ ...formData, discount_tag: e.target.value })}
                />

                <label htmlFor="other_tags"><i>Nümunə:</i> Yeni<b>/</b>Xüsusi<b>/</b>Hit</label>
                <input
                    type="text"
                    placeholder="Digər teqlər"
                    value={formData.other_tags}
                    onChange={(e) => setFormData({ ...formData, other_tags: e.target.value })}
                />


                <hr></hr>

                <legend>Sosial Media Hesabları</legend>

                <label>https://instagram.com/<b>username</b></label>
                <input
                    type="text"
                    placeholder="Instagram Username"
                    value={formData.social_media.instagram}
                    onChange={(e) => setFormData({ ...formData, social_media: { ...formData.social_media, instagram: e.target.value } })}
                />

                <label>https://tiktok.com/<b>@username</b></label>
                <input
                    type="text"
                    placeholder="Tiktok Username"
                    value={formData.social_media.tiktok}
                    onChange={(e) => setFormData({ ...formData, social_media: { ...formData.social_media, tiktok: e.target.value } })}
                />

                <label>https://facebook.com/<b>username</b></label>
                <input
                    type="text"
                    placeholder="Facebook Username"
                    value={formData.social_media.facebook}
                    onChange={(e) => setFormData({ ...formData, social_media: { ...formData.social_media, facebook: e.target.value } })}
                />

                <label>https://youtube.com/<b>rest of link</b></label>
                <input
                    type="text"
                    placeholder="Youtube"
                    value={formData.social_media.youtube}
                    onChange={(e) => setFormData({ ...formData, social_media: { ...formData.social_media, youtube: e.target.value } })}
                />

                <label>Whatsapp Linki</label>
                <input
                    type="text"
                    placeholder="Whatsapp"
                    value={formData.social_media.whatsapp}
                    onChange={(e) => setFormData({ ...formData, social_media: { ...formData.social_media, whatsapp: e.target.value } })}
                />

                <input
                    type="text"
                    placeholder="Website"
                    value={formData.social_media.website}
                    onChange={(e) => setFormData({ ...formData, social_media: { ...formData.social_media, website: e.target.value } })}
                />


                <hr></hr>
                <legend class="w-auto">Ünvan və Əlaqə</legend>
                <input
                    type="text"
                    placeholder="Full Address"
                    value={formData.address.full_address}
                    onChange={(e) => setFormData({ ...formData, address: { ...formData.address, full_address: e.target.value } })}
                />

                <label htmlFor="lat">Latitude</label>
                <input
                    type="number"
                    placeholder="Latitude"
                    value={formData.address.latitude}
                    onChange={(e) => setFormData({ ...formData, address: { ...formData.address, latitude: e.target.value } })}
                />

                <label htmlFor="long">Longitude</label>

                <input
                    type="number"
                    placeholder="Longitude"
                    value={formData.address.longitude}
                    onChange={(e) => setFormData({ ...formData, address: { ...formData.address, longitude: e.target.value } })}
                />

                <input
                    type="text"
                    placeholder="0551234544, 0121234544, 0501234544"
                    value={formData.contact}
                    onChange={(e) => setFormData({ ...formData, contact: e.target.value })}
                />

                <hr></hr>
                <legend>Digər</legend>

                <label htmlFor="category">Kateqoriya</label>
                <select
                    value={formData.category}
                    onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                >

                    <option value="0">Kateqoriya seç</option>
                    <option value="1">Əyləncə</option>
                    <option value="2">Kafelər</option>
                    <option value="3">İdman zalları</option>
                    <option value="4">Kurslar</option>
                    <option value="5">Xaricdə Təhsil</option>
                    <option value="6">Şəxsi Baxım</option>
                    <option value="7">Restoranlar</option>
                    <option value="8">Şirniyyat</option>
                    <option value="9">Səyahət</option>
                    <option value="10">Digər</option>
                    <option value="11">Aksesuar</option>
                    <option value="12">Geyim</option>

                </select>

                <label htmlFor="logo">Əsas foto</label>

                <img src={formData.logo} alt="logo" style={{ width: '200px' }} />

                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setFormData({ ...formData, logo: e.target.files[0] })}
                />

                {editMode && (
                    <>
                        <label htmlFor='other-photos'>Digər fotolar</label>
                        <div>
                            <div style={{display:'flex', }}>
                                {
                                    formData.photo && formData.photo.map((ph, index) => (
                                        <div>
                                            <img src={ph} alt
                                            ="photo" style={{ width: '100px' }} />
                                            <button 
                                                style={{ marginLeft: '10px', width: '50px', height: '30px', backgroundColor: 'red', color: 'white' }}
                                                onClick={() => {
                                                    removeImage(ph);
                                            }}>x</button>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setPhotoData({ ...photoData, photo: e.target.files[0] })}
                        />


                        <button onClick={() => {
                            uploadImage();
                        }}>Əlavə et</button>
                    </>    
                )}

                {!editMode && (
                    <>
                        <input
                            type="text"
                            placeholder="Admin ID"
                            value={formData.admin_id}
                            onChange={(e) => setFormData({ ...formData, admin_id: e.target.value })}
                        />

                        <label htmlFor="photo">Digər fotolar</label>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => setFormData({ ...formData, photo: e.target.files[0] })}
                        />
                    </>
                )
                }


                {!editMode ? (
                    <button onClick={handleSubmit} disabled={isSubmitting}>
                        {isSubmitting ? 'Gözləyin....' : 'Göndər'}
                    </button>
                ) : (
                    <button onClick={handleUpdate} disabled={isSubmitting}>
                        {isSubmitting ? 'Gözləyin....' : 'Yenilə'}
                    </button>
                )}
            </div>
        </div>
    );
};

export default BusinessFormPage;
