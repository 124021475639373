import React, { useEffect } from 'react';
// import QRCode from 'react-native-qrcode-svg';
import { useLocation } from 'react-router-dom';
import QRCode from "react-qr-code";


const RedeemPage = () => {

    if (!localStorage.getItem('user')) {
        window.location.replace('/app/auth');
    }

    const location = useLocation();
    const { campaignId, userId, campaignName, maxUses } = location.state;

    return (
        <div style={styles.container}>
            <div style={styles.wrapper}>
                <h1 style={styles.campaignTitle}>{campaignName}</h1>
                <p>Endirimdən yararlanmaq üçün QR kodu oxut</p>

                <div style={styles.qrCodeContainer}>
                    <QRCode size={200} value={`${campaignId}/${userId}/${maxUses}`} />
                </div>
            </div>
        </div>
    );
};

export default RedeemPage;


const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#fff',
    },
    wrapper: {
        width: '92%',
        marginLeft: 'auto',
        marginRight: 'auto',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
    },
    campaignTitle: {
        fontSize: '30px',
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: '10px',
    },
    qrCodeContainer: {
        textAlign: 'center',
        marginTop: '20px',
    },
};
