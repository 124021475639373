import React, { useState, useEffect } from 'react';
// import styles from './style.modules.css';

export default function SettingsPage({ history }) {
    const [expanded, setExpanded] = useState([]);
    const [userData, setUserData] = useState({});
    // const { setIsAuthenticated } = useAuth();

    if (!localStorage.getItem('user')) {
        window.location.replace('/app/auth');
    }


    const settings = [
        {
            id: "1",
            icon: "fa-regular fa-bell",
            name: "Bildirişlər",
            text: "Hal-hazırda heç bir bildirişin yoxdur.",
        },
        {
            id: "2",
            icon: "fa-solid fa-unlock",
            name: "Şifrəni dəyiş",
            link: "reset-password"
        },
        {
            id: "3",
            icon: "fa-solid fa-life-ring",
            name: "Dəstək",
            text: "Sualın var? Bizimlə contact@qroove.com e-poçt ünvanı vasitəsilə əlaqə saxla.",
        },
        {
            id: "4",
            icon: "fa-regular fa-circle-question",
            name: "Haqqımızda",
            text: "Versiya 1.0.0",
        },
        {
            id: "5",
            icon: "fa-solid fa-right-from-bracket",
            name: "Çıxış",
            link: "logout"
        },
    ];

    useEffect(() => {
        // Assuming you have some logic to get user data
        const user = JSON.parse(localStorage.getItem('user'));
        setUserData(user);
    }, []);

    const handleAccordionClick = (index) => {
        if (expanded.includes(index)) {
            setExpanded(expanded.filter((item) => item !== index)); // Collapse if already expanded
        } else {
            setExpanded([...expanded, index]); // Expand if not expanded
        }
    };

    const isExpanded = (index) => expanded.includes(index);

    const handleLinkClick = (link) => {
        if (link === "reset-password") {
            window.location.href = "/app/reset-password";
        } else if (link === "logout") {
            localStorage.removeItem('user');
            window.location.replace('/app/auth');
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.profile}>
                <p style={styles.profileText}>{userData.first_name + " " + userData.last_name}</p>
            </div>
            <div style={styles.settingsList}>
                {settings.map((setting, index) => (
                    <div key={setting.id}>
                        <div
                            onClick={() => (setting.link ? handleLinkClick(setting.link) : handleAccordionClick(index))}
                            style={styles.settingItem}
                        >
                            <div style={styles.iconContainer}>
                                <i className={setting.icon} style={{ fontSize: "25px", color: "#F16623" }}></i>
                            </div>
                            <p style={styles.settingText}>{setting.name}</p>
                            <div style={styles.toggleIconContainer}>
                                {setting.text && (
                                    <i
                                        className={isExpanded(index) ? 'fas fa-minus' : 'fas fa-plus'}
                                        size="30px"
                                        color="gray"
                                    ></i>
                                )}
                            </div>
                        </div>
                        <div style={styles.separator}></div>
                        {isExpanded(index) && (
                            <div>
                                <p style={styles.settingDescription}>{setting.text}</p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
const styles = {
    container: {
        display: "flex",
        flex: 1,
        flexDirection: 'column',
        alignItems: "center",
        padding: "20px",
    },
    profile: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    profileText: {
        fontSize: "30px",
        fontWeight: 'bold',
    },
    settingsList: {
        marginTop: "20px",
        width: "100%",
        maxWidth: "600px",
    },
    settingItem: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingRight: "20px",
        paddingLeft: "20px"
    },
    settingTextContainer: {
        display: "flex",
        flexDirection: 'row',
        alignItems: 'center',
    },
    settingText: {
        flex: 1,
        padding: "20px",
        margin: 0,
        fontSize: "25px",
    },
    separator: {
        borderBottomColor: 'lightgray',
        borderBottomWidth: "1px",
        borderBottomStyle: 'solid',
        marginHorizontal: "20px",
    },
    settingDescription: {
        padding: "20px",
        fontSize: "18px",
        color: 'gray',
    },
    toggleIconContainer: {
        alignItems: 'flex-end',
    },
};