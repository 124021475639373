// ListingPage.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import PagerView from 'react-native-pager-view';
import Campaign from '../components/Campaign';
import styles from './style.module.css';

const ListingPage = (props) => {


    if (!localStorage.getItem('user')) {
        window.location.replace('/app/auth');
    }

    // get browser url
    const url = window.location.href;
    const urlSplit = url.split('/');
    const listingId = urlSplit[urlSplit.length - 1];


    const [listingDetails, setListingDetails] = useState(null);
    const [loading, setLoading] = useState(true);


    // Fetch listing details based on listingId
    useEffect(() => {
        setLoading(true);
        // Fetch data from the API
        fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/listings/${listingId}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.json())
            .then((data) => {
                setListingDetails(data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });
    }, [listingId]);


    if (loading) {
        return (
            <div className="loadingContainer">
                <i className="fa-solid fa-spinner"></i>
            </div>
        );
    }


    return (
        <div className={styles.container}>
            {/* Business Photo */}
            <div className="viewPager">
                {listingDetails?.photo.map((photo, index) => (
                    <div className={styles.page} key={index}>
                        <img
                            className={styles.photo}
                            src={photo}
                            alt={`Photo ${index}`}
                        />
                    </div>
                ))}
            </div>

            {/* Business Content */}
            <div className={styles.contentContainer}>
                <div className={styles.wrapper}>
                    <h1 className={styles.businessName}>{listingDetails?.business_name.az}</h1>

                    {/* Business Description */}
                    <p className={styles.subsectionHeading}>Haqqında</p>
                    <p className={styles.description}>{listingDetails?.description.az}</p>

                    {/* Discounts */}
                    <p className={styles.subsectionHeading}>Endirimlər</p>
                    <div>
                        {listingDetails?.coupons && listingDetails?.coupons.length !== 0 ? (
                            listingDetails?.coupons.map((campaign, index) => (
                                <Campaign key={index} campaign={campaign} />
                            ))
                        ) : (
                            <p className={styles.description}>Hal-hazırda hər hansı bir kampaniya təklif edilmir</p>
                        )}
                    </div>

                    {/* Contacts */}
                    <p className={styles.subsectionHeading}>Telefon və ünvan</p>
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", marginLeft: 10, marginTop: 10 }}>
                            <i className="fa fa-phone" />
                            <div style={{ marginLeft: 10 }}>
                                {listingDetails?.contact.map((contact, index) => (
                                    <a href={`tel:${contact}`} key={index}>
                                        <div style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 5 }}>
                                            <p style={{ fontSize: 16 }}>{contact}</p>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginLeft: 10, marginTop: 10 }}>
                            <i className="fa fa-map-marker" />
                            <p style={{ fontSize: 16, marginLeft: 10 }}>{listingDetails?.address.full_address}</p>
                        </div>

                        {/* Map Image */}
                        <a href={`https://www.google.com/maps?q=${listingDetails?.address.latitude},${listingDetails?.address.longitude}`} target="_blank">
                            <img
                                className={styles.map}
                                src={listingDetails?.address.mapPhoto}
                                alt="Map"
                            />
                        </a>
                    </div>

                    {/* Socials */}
                    <p className={styles.subsectionHeading}>Sosial şəbəkələr</p>
                    <div style={{ display: "flex", flexDirection: "row", marginLeft: 10, marginTop: 10 }}>
                        {listingDetails?.social_media?.instagram && (
                            <div>
                                <a href={`https://www.instagram.com/${listingDetails.social_media.instagram}`} target='_blank'>
                                    <i className={`fa-brands fa-instagram ${styles.socialMediaIcon}`} />
                                </a>
                            </div>
                        )}

                        {listingDetails?.social_media?.tiktok && (
                            <div>
                                <a href={`https://www.tiktok.com/${listingDetails.social_media.tiktok}`} target='_blank'>
                                    <i className={`fa-brands fa-tiktok ${styles.socialMediaIcon}`} />
                                </a>
                            </div>
                        )}

                        {listingDetails?.social_media?.facebook && (
                            <div>
                                <a href={`https://www.facebook.com/${listingDetails.social_media.facebook}`} target='_blank'>
                                    <i className={`fa-brands fa-facebook ${styles.socialMediaIcon}`} />
                                </a>
                            </div>
                        )}

                        {listingDetails?.social_media?.website && (
                            <div>
                                <a href={`https://${listingDetails.social_media.website}`} target='_blank'>
                                    <i className={`fa-solid fa-globe ${styles.socialMediaIcon}`} />
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ListingPage;
