import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import styles from './style.module.css';
import Sidebar from '../components/sidebar';
import Modal from '../components/Modal';

const DashboardPage = () => {
    const [data, setData] = useState({
        logo: '',
        cover_photo: '',
        name: '',
        description: '',
        address: '',
        lat: '',
        long: '',
        phone: '',
        instagram: '',
        tiktok: '',
        geoLocPhoto: '',
        coupons: [],
    });

    const [listings, setListings] = useState([]);
    const [deleteStatus, setDeleteStatus] = useState({ success: false, error: null });
    const [confirmedDeletion, setConfirmedDeletion] = useState([]);
    const [activeButton, setActiveButton] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [currentCoupons, setCurrentCoupons] = useState([]);
    const [currentListingId, setCurrentListingId] = useState(null);
    const listing_id = localStorage.getItem('listing_id');

    useEffect(() => {
        if (listing_id !== '1') {
            fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/listings/${listing_id}`)
                .then((response) => response.json())
                .then((result) => {
                    setData({
                        logo: result.logo,
                        cover_photo: result.photo,
                        name: result.business_name.az,
                        description: result.description.az,
                        address: result.address.full_address,
                        lat: result.address.latitude,
                        long: result.address.longitude,
                        phone: result.contact,
                        instagram: result.social_media.instagram,
                        tiktok: result.social_media.tiktok,
                        coupons: result.coupons,
                        geoLocPhoto: result.address.mapPhoto,
                    });
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        } else {
            fetch('https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/listings')
                .then((response) => response.json())
                .then((result) => {
                    setListings(result.listings);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [listing_id]);

    const deleteListing = (listingId) => {
        fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/listings/${listingId}`, {
            method: 'DELETE',
        })
            .then((response) => {
                if (response.status === 200) {
                    setDeleteStatus({ success: true, error: null });
                    const updatedListings = listings.filter((listing) => listing.id !== listingId);
                    setListings(updatedListings);
                    console.log(`Listing with ID ${listingId} deleted successfully.`);
                } else {
                    setDeleteStatus({ success: false, error: 'Something went wrong while deleting the listing.' });
                    console.error(`Error deleting listing with ID ${listingId}`);
                }
                setTimeout(() => {
                    setDeleteStatus({ success: false, error: null });
                }, 2000);
            })
            .catch((error) => {
                console.error('Error deleting listing:', error);
                setDeleteStatus({ success: false, error: 'Something went wrong while deleting the listing.' });
                setTimeout(() => {
                    setDeleteStatus({ success: false, error: null });
                }, 2000);
            });
        setActiveButton(null);
    };

    const handleToggleConfirmation = (listingId) => {
        if (confirmedDeletion.includes(listingId)) {
            deleteListing(listingId);
        } else {
            setConfirmedDeletion([...confirmedDeletion, listingId]);
            setActiveButton(listingId);
        }
    };

    const handleEdit = (listingId) => {
        console.log('Edit button clicked for listing ID:', listingId);
        window.location.href = `/edit-business/${listingId}`;
    };

    const handleServices = (listingId) => {
        console.log('Services button clicked for listing ID:', listingId);
        window.location.href = `/edit-services/${listingId}`;
    };

    const handleCampaigns = (listingId) => {
        const selectedListing = listings.find((listing) => listing.id === listingId);
        setCurrentCoupons(selectedListing ? selectedListing.coupons : []);
        console.log(selectedListing ? selectedListing.coupons : []);
        setCurrentListingId(listingId);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setCurrentCoupons([]);
    };

    const deleteCoupon = (couponId) => {
        if (window.confirm('Are you sure you want to delete this coupon?')) {
            fetch(`https://us-central1-qroovy-a9b3a.cloudfunctions.net/app/coupons/${couponId}`, {
                method: 'DELETE',
            })
                .then((response) => {
                    if (response.status === 200) {
                        const updatedCoupons = currentCoupons.filter((coupon) => coupon.id !== couponId);
                        setCurrentCoupons(updatedCoupons);
                        console.log(`Coupon with ID ${couponId} deleted successfully.`);
                    } else {
                        console.error(`Error deleting coupon with ID ${couponId}`);
                    }
                })
                .catch((error) => {
                    console.error('Error deleting coupon:', error);
                });
        }
    };

    const updateCoupon = (couponId) => {
        console.log(`Update coupon with ID ${couponId}`);
        window.location.href = `/edit-campaign/${couponId}`;
    };

    const createNewCampaign = (listingID) => {
        console.log('Create new campaign button clicked');
        window.location.href = `/create-campaign/${listingID}`;
    };

    return (
        <>
            <Modal isOpen={modalOpen} onClose={closeModal}>
                <h2>Coupons</h2>
                {currentCoupons.length > 0 ? (
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">ID</th>
                                <th scope="col">Title</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentCoupons.map((coupon) => (
                                <tr key={coupon.id}>
                                    <th scope="row">{coupon.id}</th>
                                    <td>{coupon.name.az}</td>
                                    <td>
                                        {/* <button className="btn btn-primary" onClick={() => updateCoupon(coupon.id)}>Update</button> */}
                                        <button className="btn btn-danger" onClick={() => deleteCoupon(coupon.id)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No campaigns found.</p>
                )}
                <button className="btn btn-success createCampaignBtn" onClick={() => createNewCampaign(currentListingId)}>Create New Campaign</button>
            </Modal>

            {listing_id !== '1' ? (
                <div className={styles.wrapper}>
                    <div className={styles.sidebarContainer}>
                        <Sidebar data={data} />
                    </div>
                    <div className={styles.mainContent}>
                        <div className={styles.photosSection}>
                            <img src={data.logo} className={styles.logo} alt="Logo" />
                            <img src={data.cover_photo} className={styles.coverPhoto} alt="Cover Photo" />
                        </div>
                        <div className={styles.nameAndDescSection}>
                            <div className={styles.name}>
                                <h1>{data.name}</h1>
                            </div>
                            <div className={styles.description}>
                                <p>{data.description}</p>
                            </div>
                        </div>
                        <div className={styles.addressSection}>
                            <div className={styles.textAddress}>
                                <h3>Address</h3>
                                <p>{data.address}</p>
                                <p>Lat: {data.lat}; Long: {data.long}</p>
                                <p>Phone: {data.phone}</p>
                                <p>Instagram: <a href={data.instagram}>instagram.com</a></p>
                                <p>Tiktok: <a href={data.tiktok}>tiktok.com</a></p>
                            </div>
                            <div className={styles.geoLocPhoto}>
                                <img src={data.geoLocPhoto} alt="Geo Location" />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.wrapper}>
                    <div className={styles.sidebarContainer}>
                        <Sidebar data={data} />
                    </div>
                    <div className={styles.mainContent}>
                        <div className={styles.header}>
                            <h1>Bizneslər</h1>
                            <Link to="/new-business" className="btn btn-primary">
                                Yeni Biznes Əlavə Et
                            </Link>
                        </div>
                        <div className={styles.tableContainer}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">Business Name</th>
                                        <th scope="col">Rating</th>
                                        <th scope="col">Photo</th>
                                        <th scope="col">Lat</th>
                                        <th scope="col">Long</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listings.map((listing) => (
                                        listing.id !== 'categories' ? (
                                            <tr key={listing.id}>
                                                <th scope="row">{listing.id}</th>
                                                <td>{listing.business_name.az}</td>
                                                <td>{listing.rating}</td>
                                                <td>
                                                    <img src={listing.photo[0]} alt="Business Photo" style={{ height: 100 }} />
                                                </td>
                                                <td>{listing.lat}</td>
                                                <td>{listing.long}</td>
                                                <td>
                                                    <button
                                                        className={`btn btn-success ${activeButton === listing.id ? 'active' : ''}`}
                                                        onClick={() => handleCampaigns(listing.id)}
                                                    >
                                                        Campaigns
                                                    </button>
                                                    <button
                                                        className={`btn btn-info ${activeButton === listing.id ? 'active' : ''}`}
                                                        onClick={() => handleServices(listing.id)}
                                                    >
                                                        Services
                                                    </button>
                                                    <button
                                                        className={`btn btn-primary ${activeButton === listing.id ? 'active' : ''}`}
                                                        onClick={() => handleEdit(listing.id)}
                                                    >
                                                        Edit
                                                    </button>
                                                    <button
                                                        className={`btn ${activeButton === listing.id ? 'btn-success' : ''}`}
                                                        onClick={() => handleToggleConfirmation(listing.id)}
                                                    >
                                                        {confirmedDeletion.includes(listing.id) ? 'Confirm' : 'Delete'}
                                                    </button>
                                                </td>
                                            </tr>
                                        ) : null
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {deleteStatus.success && (
                            <p className="alert alert-success">Listing deleted successfully</p>
                        )}
                        {deleteStatus.error && (
                            <p className="alert alert-danger">{deleteStatus.error}</p>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default DashboardPage;
